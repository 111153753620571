<template>
  <div class="colors-range-container">
    <button
      v-for="(color, index) in colors"
      class="button"
      :title="color"
      :style="{ backgroundColor: color }"
      :key="color"
    >
      {{ index + 1 }}
    </button>
  </div>
</template>

<script>
import Gradient from 'javascript-color-gradient';

export default {
  name: 'ColorsRange',

  props: {
    startColor: {
      type: String,
      required: true
    },
    finalColor: {
      type: String,
      required: true
    },
    midpoint: {
      type: Number,
      required: true,
      default: 2
    }
  },

  computed: {
    colors() {
      if (!this.startColor || !this.finalColor || !this.midpoint) return [];

      return new Gradient()
        .setColorGradient(this.startColor, this.finalColor)
        .setMidpoint(this.midpoint)
        .getColors();
    }
  },

  watch: {
    colors() {
      this.$emit('newColors', this.colors);
    }
  }
};
</script>

<style>
.colors-range-container {
  max-height: 300px;
  overflow-y: auto;
}

.colors-range-container .button {
  width: 60px;
  height: 60px;
  margin: 2px 1px;
  border: none;
  border-radius: 9px;
  cursor: pointer;
  color: aliceblue;
}
</style>
